<template>
  <div class="box">
    <a-button
      type="primary"
      class="switch-user"
      @click="switchUser"
      v-if="isCanSwitchUser"
    >
      一键登录
    </a-button>
    <a-tabs
      :active-key="activeKey"
      @change="changeTab"
    >
      <a-tab-pane
        v-for="item in tabs"
        :key="item.routeName"
        :tab="item.tabName"
      >
        <router-view v-if="activeKey===item.routeName" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findAgentEffective, findAgentName, isTopAgent } from '@/api/agent'
import { switchUser } from '@/api/switch_user'
import { getOriginalUserUid } from '@/utils/token'

export default {
  name: 'AgentShowTab',
  data() {
    return {
      activeKey: '',
      isCanSwitchUser: false,
      tabs: [],
      topAgentTabs: [
        { routeName: 'agent_info', tabName: '客户信息' },
        { routeName: 'agent_products', tabName: '客户套餐' },
        { routeName: 'agent_sim_cards', tabName: '卡号列表' },
        { routeName: 'agent_traffic_pools', tabName: '流量池' },
        { routeName: 'service_cycle_unexpired', tabName: '服务周期管理' },
        // { routeName: 'agent_sms_templates', tabName: '短信模版管理' },
        { routeName: 'agent_finance', tabName: '财务信息' },
        { routeName: 'agent_commission', tabName: '分佣信息' },
        { routeName: 'agent_self_purchase_orders', tabName: '采购订单' },
        { routeName: 'agent_agencies', tabName: '企业客户' }
      ],
      agencyTabs: [
        { routeName: 'agent_info', tabName: '客户信息' },
        { routeName: 'agent_finance', tabName: '财务信息' },
        { routeName: 'agent_commission', tabName: '分佣信息' },
        { routeName: 'agent_agencies', tabName: '企业客户' }
      ],
      agentInfoChildrenTabs: [
        'agent_info',
        'agent_vpdn_manage',
        'agent_vpdn_manage_info',
        'agent_setting',
        'agent_api_info',
        'agent_enterprise_qualification',
        'agent_receive_addresses',
        'agent_files',
        'agent_customize_site'
      ],
      agentFinanceChildrenTabs: [
        'agent_finance',
        'agent_monthly_bills',
        'agent_transfers',
        'agent_balance_statistics',
        'agent_monthly_bill_info',
        'agent_monthly_bill_card_use_info',
        'agent_monthly_bill_renew_order_info',
        'agent_monthly_bill_stop_info',
        'agent_consumption_bills',
        'agent_consumption_bill_renew_order_info',
        'agent_monthly_bill_subscribe_order_info',
        'agent_consumption_bill_subscribe_order_info'
      ],
      agentCommissionChildrenTabs: [
        'agent_commission',
        'agent_monthly_bills_commission',
        'agent_withdrawals',
        'agent_commission_statistics',
        'agent_monthly_bill_info_commission',
        'agent_monthly_bill_card_use_info_commission',
        'agent_monthly_bill_renew_order_info_commission',
        'agent_monthly_bill_subscribe_order_info_commission',
        'agent_monthly_bill_stop_info_commission'
      ],
      agentTrafficPoolChildrenTabs: [
        'agent_traffic_pools',
        'agent_traffic_pool_info',
        'agent_traffic_pool_sim_cards',
        'agent_traffic_pool_expand_records',
        'agent_traffic_pool_usage_orders'
      ],
      agentServiceCycleManageChildrenTabs: [
        'service_cycle_unexpired',
        'service_cycle_expired',
        'current_month_expect_cancel',
        'service_cycle_renewed',
        'service_cycle_canceled'
      ]
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  watch: {
    agentId() {
      this.initTabs()
      this.setBreadcrumb()
      this.setIsCanSwitchUser()
    }
  },
  created() {
    this.initTabs()
    this.setBreadcrumb()
    this.setIsCanSwitchUser()
  },
  methods: {
    initTabs() {
      const routeName = this.$route.name
      if (this.agentInfoChildrenTabs.includes(routeName)) {
        this.activeKey = 'agent_info'
      } else if (this.agentFinanceChildrenTabs.includes(routeName)) {
        this.activeKey = 'agent_finance'
      } else if (this.agentCommissionChildrenTabs.includes(routeName)) {
        this.activeKey = 'agent_commission'
      } else if (this.agentTrafficPoolChildrenTabs.includes(routeName)) {
        this.activeKey = 'agent_traffic_pools'
      } else if (this.agentServiceCycleManageChildrenTabs.includes(routeName)) {
        this.activeKey = 'service_cycle_unexpired'
      } else {
        this.activeKey = routeName
      }

      isTopAgent(this.agentId).then((res) => {
        if (res.code === 0) {
          var isTopAgent = res.data.is_top_agent

          if (isTopAgent) {
            this.tabs = this.topAgentTabs
          } else {
            this.tabs = this.agencyTabs
          }
        }
      })
    },

    setBreadcrumb() {
      findAgentName(this.agentId).then((res) => {
        if (res.code === 0) {
          this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':agent_name': res.data.name })
        }
      })
    },

    setIsCanSwitchUser() {
      findAgentEffective(this.agentId).then((res) => {
        if (res.code === 0) {
          this.isCanSwitchUser = res.data.effective
        }
      })
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    changeTab(activeKey) {
      this.activeKey = activeKey
      this.$router.push({ name: activeKey })
    },

    switchUser() {
      switchUser({ agent_id: this.agentId, original_user_uid: getOriginalUserUid() }).then((res) => {
        if (res.code === 0) {
        //   location.href = res.data.redirect_url
        //   window.open(res.data.redirect_url)
          setTimeout(function() {
            window.open(res.data.redirect_url)
          }, 500)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  position: relative;

  .switch-user {
    position: absolute;
    right: 0;
    z-index: 9;
  }
}
</style>
